<template>
  <div class="main-title-bar">
    <div class="main-title-bar-left">
      <button id="toggle-navicon" @click="toggleSidebar">
        <i class="fa-solid fa-bars"></i>
      </button>
      <h5 v-if="site">{{ site.siteTitle || site.uri }}</h5>
      <h5 v-else>Dynamic Builder</h5>
      <div class="top-menu-action-bar">
        <ul>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-title="Setting"
            title="Setting"
          >
            <!-- <a href="javascript:void(0);"><i class="fa-solid fa-gear"></i></a> -->
            <SiteSettings
              v-if="site && showSettings"
              :title="site.siteTitle"
              :uri="site.uri"
              :extFiles="site.extFiles"
              :fullReload="site.fullReload"
              :ajaxReload="site.ajaxReload"
              :loginInclude="site.loginInclude"
              :loginRequired="site.loginRequired"
              :enableMobile="site.enableMobile"
              :enableIpad="site.enableIpad"
              :authUrl="site.authUrl"
              :mobileQuery="site.mobileQuery"
              :authAPIMethod="site.authAPIMethod"
              :globalCss="site.globalCss"
              :globalJs="site.globalJs"
              :globalVariables="site.globalVariables || []"
              v-bind="$attrs"
            />
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-title="Permission"
            title="Permission"
          >
            <a v-if="isSuperAdmin()" href="/site-permission"
              ><i class="fa-solid fa-user-shield"></i
            ></a>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-title="View Site"
            title="View Site"
          >
            <span @click="openSitePage" class="pointer"><i class="fa-solid fa-eye"></i></span>
          </li>
          <!-- <li
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-title="Querystring"
            title="Querystring"
          >
            <a href="javascript:void(0);"
              ><i class="fa-solid fa-circle-question"></i
            ></a>
          </li> -->
          <li
            class="save-blue-color"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-title="Save"
            title="Save"
          >
            <span @click="$emit('save')" class="pointer"
              ><i class="fa-solid fa-floppy-disk"></i
            ></span>
          </li>
        </ul>
      </div>
    </div>
    <!-- main-title-bar -->
    <div class="main-title-bar-right">
      <input
          :defaultValue="site.defaultQuery"
          type="text"
          class="query-string w-32 px-2"
          placeholder="query string"
          :id="`querystring`"
          @blur="isQueryInputFocused = false"
          @focus="isQueryInputFocused = true"
          :class="{ 'focused-input' : isQueryInputFocused}"
          @change="$emit('update', { defaultQuery: $event.target.value })"
        />
      <select class="form-select" :value="selectedVersion" @change="$emit('selectVersion', $event.target.value)">
        <!-- <option selected>2.0 Current Published</option>
        <option value="1">1.0</option> -->
        <option v-for="version of versionData.versions" :key="version" :value="version">
          {{version}}
          <small class="mx-2" v-if="version === versionData.current_version">
            current
          </small>
          <small class="mx-2" v-if="version === versionData.published_version">
            published
          </small>
        </option>
      </select>
      <div class="top-menu-action-bar">
        <ul>
          <li
            v-if="selectedVersion !== versionData.published_version"
            class="save-blue-color"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-title="Set as published version"
            title="Set as published version"
            @click="publishVersion"
          >
            <a href="javascript:void(0);"><i class="fa-solid fa-gear"></i></a>
          </li>
          <li
          v-if="selectedVersion !== versionData.current_version"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-title="Set as current version"
            title="Set as current version"
            @click="$emit('setMetaVersion', {
              current_version: selectedVersion
            })"
          >
            <a href="javascript:void(0);"
              ><i class="fa-regular fa-pen-to-square"></i
            ></a>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-title="Add New Version"
            title="Add New Version"
            @click="$emit('addNewVersion')"
          >
            <a href="javascript:void(0);"><i class="fa-solid fa-copy"></i></a>
          </li>
          <li
            v-if="![versionData.published_version, versionData.current_version].includes(selectedVersion)"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-title="Delete"
            title="Delete"
            @click="deleteVersion"
            class="delete-version"
          >
            <a href="javascript:void(0);"
              ><i class="fa-solid fa-trash-can"></i
            ></a>
          </li>
        </ul>
      </div>
      <a @click="logout" href="javascript:void(0);" class="btn btn-default btn-black ck"
        ><i class="fa-solid fa-power-off"></i> Logout</a
      >
    </div>
    <!-- main-title-bar-right -->
  </div>
  <!-- main-title-bar -->
</template>
<script>
import * as authService from '../service/auth'
import SiteSettings from '../components/SiteSettings.vue'
import { viewer_url } from '../constants'

export default {
  name: 'PageHeader',
  components: { SiteSettings },
  props: {
    site: {
      type: Object,
      default: () => null
    },
    showSettings: {
      type: Boolean,
      default: true
    },
    versionData: {
      type: Object,
      default: () => ({
        versions: []
      })
    },
    selectedVersion: String,
    isListPage: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return{
      isQueryInputFocused: false,
    }
  },
  mounted () {
    if (this.site && this.site.uri) {
      this.$emit('update', { name: this.site.uri })
    }
  },
  methods: {
    openSitePage () {
      const { uri } = this.$route.params;
      const query = document.querySelector(`#querystring`).value
      const url = `${viewer_url}/${uri}?${query}${query ? '&': ''}v=${this.selectedVersion}`
      window.open(url, '_blank')
    },
    logout () {
      window.localStorage.removeItem('token')
      window.location.href = '/login'
    },
    isSuperAdmin() {
      const user = authService.getUser()
      console.log('user: ', user);
      return user && user.is_super_admin
    },
    async publishVersion () {
      if (window.confirm('Are you sure want to publish this version?')) {
        this.$emit('setMetaVersion', {
          published_version: this.selectedVersion
        })
      }
    },
    async deleteVersion () {
      if (window.confirm('Are you sure want to delete this version?')) {
        this.$emit('deleteVersion', this.selectedVersion)
      }
    },
    toggleSidebar(){
      this.$emit("toggleSidebar")
    }
  }
}
</script>
<style scoped>
.delete-version *{
  color: red !important;
}
.query-string{
  border-radius: 19px;
  background-color: #ffffff;
  border: 1px solid #ededff;
  box-shadow: none !important;
  font-size: 14px;
  height: 36px;
  outline: none;
}
.focused-input{
  max-width: 11em !important;
  width: 100%;
}
</style>