<template>
  <div class="settings-page">
    <label @click="showModal = !showModal" class="cursor-pointer">
      <font-awesome-icon icon="cog" />
    </label>
    <vue-final-modal
      v-model="showModal"
      :click-to-close="false"
      :content-style="{
        width: '100vw',
        height: '100vh',
      }"
    >
      <div class="bg-white inline-flex px-6 py-4 w-full h-full overflow-auto">
        <div class="w-1/2 pr-4">
          <div class="flex items-center mb-2">
            <img
              src="@/assets/heading-point.svg"
              alt="Warning"
              class="h-6 w-6 mr-2"
            />
            <span class="text-lg font-bold">Site Settings</span>
          </div>

          <div class="overflow-y-auto px-4 " style='height: calc(100vh - 374px) !important'>
            <div class="flex mb-2 mt-1">
              <label
                class="text-left text-sm font-medium w-36 mt-1 text-gray-700"
              >
                Site Title :
              </label>
              <input
                type="text"
                :defaultValue="title"
                placeholder="Site Title"
                class="flex-grow px-3 py-2 border border-gray-300 custom-focus rounded-md h-8"
                @change="updateSiteTitle($event.target.value)"
              />
            </div>
            <div class="flex mt-3">
              <label class="text-left text-sm font-medium w-36 text-gray-700"
                >Auth :
              </label>
              <div class="flex items-center">
                <div class="flex items-center">
                  <input
                    :defaultChecked="loginInclude"
                    type="checkbox"
                    class="mr-2 custom-checkbox"
                    @change="updateField('loginInclude', $event.target.checked)"
                  />
                  <label class="mr-3 text-xs text-left font-normal">
                    Include Login
                  </label>
                </div>

                <div class="flex items-center">
                  <input
                    :disabled="!loginInclude"
                    :defaultChecked="loginRequired"
                    type="checkbox"
                    class="mr-2 custom-checkbox"
                    @change="
                      updateField('loginRequired', $event.target.checked)
                    "
                  />
                  <label class="mr-3 text-xs text-left font-normal">
                    Login Required
                  </label>
                </div>

                <div class="flex items-center">
                  <input
                    :defaultChecked="enableMobile"
                    type="checkbox"
                    class="mr-2 custom-checkbox"
                    @change="updateField('enableMobile', $event.target.checked)"
                  />
                  <label class="mr-3 text-xs text-left font-normal">
                    Enable Mobile
                  </label>
                </div>

                <div class="flex items-center">
                  <input
                    :defaultChecked="enableIpad"
                    type="checkbox"
                    class="mr-2 custom-checkbox"
                    @change="updateField('enableIpad', $event.target.checked)"
                  />
                  <label class="mr-3 text-xs text-left font-normal">
                    Enable Ipad
                  </label>
                </div>
              </div>
            </div>

            <!-- AUTH API URL FIELD -->
            <div class="flex mt-3">
              <label
                class="text-left text-sm font-medium w-36 mt-1 text-gray-700"
              >
                Auth API URL :
              </label>
              <select
                :value="authAPIMethod"
                @change="updateField('authAPIMethod', $event.target.value)"
                class="px-2 py-1 border border-gray-300 custom-focus rounded-md w-20 mr-2 text-sm"
              >
                <option>GET</option>
                <option>POST</option>
                <option>PUT</option>
              </select>
              <input
                class="flex-grow px-3 py-2 border border-gray-300 custom-focus rounded-md h-8"
                type="text"
                placeholder="Auth URL"
                :defaultValue="authUrl"
                @change="updateField('authUrl', $event.target.value)"
              />
            </div>

            <!-- App Query String FIELD -->
            <div class="flex mt-3">
              <label
                class="text-left text-sm font-medium w-36 mt-1 text-gray-700"
              >
              App Query String :
              </label>
              <input
                class="flex-grow px-3 py-2 border border-gray-300 custom-focus rounded-md h-8"
                type="text"
                placeholder="App Query String"
                :defaultValue="mobileQuery"
                @change="updateField('mobileQuery', $event.target.value)"
              />
            </div>

            <div class="flex items-center mt-3">
              <label
                class="text-left text-sm font-medium w-36 mt-1 text-gray-700"
              >
                Full Reload :
              </label>
              <input
                class="text-right w-20 px-3 py-2 border border-gray-300 rounded-md custom-focus h-8"
                type="number"
                :defaultValue="fullReload"
                @change="updateField('fullReload', $event.target.value)"
              />
              <span class="ml-2 text-sm">secs</span>
            </div>
            <div class="flex items-center mt-3">
              <label
                class="text-left text-sm font-medium w-36 mt-1 text-gray-700"
              >
                Ajax Reload :
              </label>
              <input
                class="text-right w-20 px-3 py-2 border border-gray-300 rounded-md custom-focus h-8"
                type="number"
                :defaultValue="ajaxReload"
                @change="updateField('ajaxReload', $event.target.value)"
              />
              <span class="ml-2 text-sm">secs</span>
            </div>

            <hr class="w-full my-3" />

            <div class="font-medium mt-3 text-sm text-gray-700">
              External files
            </div>
            <!-- Files loop section -->
            <div v-for="(file, idx) of orderedExtFile" :key="idx">
              <!-- Display "Scripts" and "Styles" sections -->
              <div
                v-if="idx === 0"
                class="font-semibold text-xs text-gray-700 mt-2 ml-2"
              >
                <span class="bullet-dot mr-2"></span> Scripts
              </div>
              <div
                v-if="idx === this.filesCount['script']"
                class="font-semibold text-xs text-gray-700 mt-3 ml-2"
              >
                <span class="bullet-dot mr-2"></span>Styles
              </div>

              <!-- File input and options section -->
              <div class="flex items-center mt-2 w-full">
                <span class="mx-2">
                  {{
                    (idx >= this.filesCount["script"]
                      ? idx - this.filesCount["script"]
                      : idx) + 1
                  }}:
                </span>
                <select
                  :value="file.type"
                  class="px-2 py-1 border border-gray-300 custom-focus rounded-md w-20 mr-2 text-sm"
                  @change="updateDepFile('type', $event.target.value, idx)"
                >
                  <option value="style">style</option>
                  <option value="script">script</option>
                </select>
                <input
                  class="flex-1 px-3 py-2 border border-gray-300 rounded-md custom-focus h-8"
                  type="text"
                  :defaultValue="file.url"
                  placeholder="url"
                  @change="updateDepFile('url', $event.target.value, idx)"
                  :disabled="file.url.startsWith('file:')"
                />
                <span
                  class="ml-2 cursor-pointer opacity-30 hover:opacity-95"
                  v-if="file.url.startsWith('file:')"
                  @click="handleEditFile(file.url)"
                >
                  <font-awesome-icon icon="edit" />
                </span>
                <span
                  class="ml-2 cursor-pointer opacity-30 hover:opacity-95"
                  @click="removeDepFile(idx)"
                >
                  <font-awesome-icon icon="minus" />
                </span>

                <!-- Sorting buttons -->
                <div class="flex ml-2">
                  <span class="cursor-pointer ml-2" @click="sortFile(-1, idx)">
                    <font-awesome-icon icon="caret-up" />
                  </span>
                  <span class="cursor-pointer ml-2" @click="sortFile(1, idx)">
                    <font-awesome-icon icon="caret-down" />
                  </span>
                </div>
              </div>
            </div>

            <!-- New file input section -->
            <div class="flex items-center mt-3">
              <select
                class="px-2 py-1 border border-gray-300 custom-focus rounded-md w-20 mr-2 text-sm"
                v-model="newDep.type"
              >
                <option value="style">style</option>
                <option value="script">script</option>
              </select>

              <input
                v-model="newDep.url"
                class="flex-1 px-3 py-2 border border-gray-300 rounded-md custom-focus h-8"
                type="text"
                placeholder="url"
                :disabled="newDep.url.startsWith('file:')"
              />

              <span
                v-if="newDep.url.startsWith('file:')"
                @click="newDep.url = ''"
                class="mx-2 cursor-pointer opacity-30 hover:opacity-95"
              >
                <font-awesome-icon icon="times" />
              </span>

              <span class="mx-4 text-gray-600 text-sm">or</span>

              <!-- Custom file input -->
              <label
                for="file-upload"
                class="cursor-pointer text-sm px-4 py-2 text-white rounded-md h-8 flex items-center justify-center transition-all custom-file-input text-center"
                :class="{ 'opacity-70': newDep.loading }"
              >
                <span v-if="newDep.loading">
                  <i class="fa-solid fa-spinner fa-spin mr-2"></i>
                  Uploading...
                </span>
                <span v-else class="lg:text-base md:text-sm">Choose File</span>
              </label>
              <input
                :key="newDep.url"
                type="file"
                id="file-upload"
                class="hidden"
                @change="handleUploadFile"
                :disabled="newDep.loading"
              />

              <span
                class="ml-2 cursor-pointer opacity-30 hover:opacity-95"
                @click="addDepFile"
              >
                <font-awesome-icon icon="plus" />
              </span>
            </div>

            <hr class="w-full my-4 mb-1" />

            <div class="flex flex-col mt-4">
              <label class="mb-1 font-medium text-sm text-gray-700">
                Global Variables
              </label>

              <div>
                <div
                  v-for="(variable, idx) of globalVariables"
                  :key="idx"
                  class="flex items-center mt-2"
                >
                  <input
                    :defaultValue="variable.name"
                    type="text"
                    placeholder="name"
                    class="w-32 ml-1 px-3 py-2 border border-gray-300 rounded-md custom-focus mr-2 h-8"
                    @change="
                      updateVariableField('name', $event.target.value, idx)
                    "
                  />
                  <input
                    :defaultValue="variable.value"
                    type="text"
                    placeholder="value"
                    class="flex-1 px-3 py-2 border border-gray-300 rounded-md custom-focus h-8"
                    @change="
                      updateVariableField('value', $event.target.value, idx)
                    "
                  />
                  <span
                    class="ml-2 cursor-pointer opacity-30 hover:opacity-95"
                    @click="removeVariable(idx)"
                  >
                    <font-awesome-icon icon="minus" />
                  </span>
                </div>

                <div class="flex items-center my-2 ml-1">
                  <input
                    v-model="newVariable.name"
                    type="text"
                    placeholder="name"
                    class="w-32 px-3 py-2 border border-gray-300 rounded-md custom-focus mr-2 h-8"
                  />
                  <input
                    v-model="newVariable.value"
                    type="text"
                    placeholder="value"
                    class="flex-1 px-3 py-2 border border-gray-300 rounded-md custom-focus h-8"
                  />
                  <span
                    class="ml-2 cursor-pointer opacity-30 hover:opacity-95"
                    @click="addVariable()"
                  >
                    <font-awesome-icon icon="plus" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <hr class="mb-3 mt-2.5" />
          <div>
            <div class="mb-4 flex justify-end">
              <button
                class="bg-gray-300 hover:bg-gray-400 text-gray-800 py-1 px-2 rounded self-settings-cancel-btn"
                @click="close"
                :disabled="isSaving"
              >
                Close
              </button>
              <button
                class="ml-2 text-white py-1 px-2 rounded self-settings-add-btn"
                @click="save"
                :disabled="isSaving"
              >
                <span v-if="isSaving">
                  <i class="fa-solid fa-spinner fa-spin"></i>
                </span>
                <span v-else>Save</span>
              </button>
            </div>

            <!-- Danger Zone -->
            <div class="bg-red-50 border border-red-300 rounded-lg p-6 pb4">
              <div class="flex items-center mb-2">
                <div>
                  <img
                    src="@/assets/danger-warning.svg"
                    alt="Warning"
                    class="h-5 w-5 mr-2"
                  />
                </div>
                <h3
                  class="text-red-600 font-semibold flex items-center text-lg"
                >
                  Danger Zone
                </h3>
              </div>

              <div class="space-y-4">
                <div class="flex items-center justify-between">
                  <div>
                    <h4 class="text-sm font-medium text-gray-900">
                      Change Site URI
                    </h4>
                    <p class="text-xs text-gray-500">
                      Update the site's URI configuration
                    </p>
                  </div>
                  <button
                    class="bg-red-600 hover:bg-red-700 text-white py-2 px-3 rounded-3xl transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 text-sm"
                    @click="$emit('updateURI')"
                  >
                    Change URI
                  </button>
                </div>
                <div class="border-t border-red-200 pt-4 mt-4">
                  <div class="flex items-center justify-between">
                    <div>
                      <h4 class="text-sm font-medium text-gray-900">
                        Delete this site
                      </h4>
                      <p class="text-xs text-gray-500">
                        Permanently remove this site and all its data
                      </p>
                    </div>
                    <button
                      class="bg-red-600 hover:bg-red-700 text-white py-2 px-3 rounded-3xl transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 text-sm"
                      @click="$emit('deleteSite')"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-1/2 pl-2 h-full flex flex-col">
          <div class="flex flex-col">
            <div class="flex flex-col">
              <div class="flex items-center mb-3">
                <img
                  src="@/assets/heading-point.svg"
                  alt="Warning"
                  class="h-6 w-6 mr-2"
                />
                <span class="text-lg font-bold">Global Style Inject</span>
              </div>
              <div
                class="overflow-auto border border-gray-200 p-1 rounded-md shadow-sm global-editor-max-height"
              >
                <TemplateEditor
                  :template="globalCss"
                  lang="css"
                  @set="updateCss"
                />
              </div>
            </div>
            <div class="flex flex-col mt-6 mb-6">
              <div class="flex items-center mb-3">
                <img
                  src="@/assets/heading-point.svg"
                  alt="Warning"
                  class="h-6 w-6 mr-2"
                />
                <span class="text-lg font-bold">Global Javascript</span>
              </div>
              <div
                class="overflow-auto border border-gray-200 rounded-md p-1 shadow-sm global-editor-max-height"
              >
                <TemplateEditor :template="globalJs" @set="updateJs" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <vue-final-modal v-model="showFileEdit" :click-to-close="false">
        <div class="bg-white w-xl h-xl mx-auto my-24 max-w-3xl">
          <div class="px-3 py-2">Edit {{ editFile.key }}</div>
          <hr />
          <div class="max-h-96 overflow-auto">
            <TemplateEditor
              :template="editFile.content"
              @set="(code) => (editFile.content = code)"
            />
          </div>
          <hr />
          <div class="px-3 py-2 flex justify-end">
            <button
              class="px-3 py-1 rounded mr-3 cursor-pointer bg-gray-200"
              :class="{ 'opacity-50': editFileSaving }"
              @click="saveEditFile"
            >
              Save
            </button>
            <button
              class="px-3 py-1 rounded mr-6 cursor-pointer bg-gray-200"
              @click="closeEditFile"
            >
              Close
            </button>
          </div>
        </div>
      </vue-final-modal>
    </vue-final-modal>
  </div>
</template>
<script>
import { api_host } from '../constants';
import { uploadFile } from '../service/site';
import TemplateEditor from './TemplateEditor';

export default {
  name: "SiteSettings",
  components: {
    TemplateEditor
  },
  props: {
    title: {
      type: String,
      default: () => "",
    },
    extFiles: {
      type: Array,
      default: () => [],
    },
    fullReload: {
      type: [Number, String],
      default: 0,
    },
    ajaxReload: {
      type: [Number, String],
      default: 0,
    },
    loginInclude: {
      type: Boolean,
      default: false
    },
    loginRequired: {
      type: Boolean,
      default: false
    },
    authUrl: {
      type: String,
      default: () => ""
    },
    mobileQuery: {
      type: String,
      default: () => ""
    },
    authAPIMethod: {
      type: String,
      default: () => "GET"
    },
    globalCss: {
      type: String,
      default: () => ""
    },
    globalJs: {
      type: String,
      default: () => ""
    },
    globalVariables: {
      type: Array,
      default: () => []
    },
    enableMobile: {
      type: Boolean,
      default: false
    },
    enableIpad: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false,
      editFile: {
        key: '',
        content: ''
      },
      newDep: {
        type: "script",
        url: "",
        loading: false
      },
      newVariable: {
        name: '',
        value: ''
      },
      showFileEdit: false,
      editingFile: {
        key: '',
        content: ''
      },
      editFileSaving: false,
      isSaving: false,
    };
  },
  computed: {
    orderedExtFile () {
      return [...this.extFiles].sort((a, b) => {
        return a.type.localeCompare(b.type) || a.order - b.order
      })
    },
    filesCount () {
      return {
        'script': this.extFiles.filter(s => s.type === 'script').length,
        'style': this.extFiles.filter(s => s.type === 'script').length
      }
    },
  },
  methods: {
    close() {
      this.showModal = false;
    },
    async save() {
      this.isSaving = true;
      try {
        await new Promise((resolve) => {
          this.$emit("save", resolve);
        });
      } finally {
        this.isSaving = false;
        this.close();
      }
    },
    updateDepFile(field, value, idx) {
      const newExtFiles = this.extFiles.map((ext, eidx) => {
        return eidx === idx
          ? {
              ...ext,
              [field]: value,
            }
          : ext;
      });
      this.$emit("update", { extFiles: newExtFiles });
    },
    removeDepFile(idx) {
      const clone = JSON.parse(JSON.stringify(this.extFiles));
      clone.splice(idx, 1);
      this.$emit("update", { extFiles: clone });
    },
    addDepFile() {
      this.$emit("update", {
        extFiles: this.extFiles.concat({
          id: this.extFiles.length,
          type: this.newDep.type,
          url: this.newDep.url,
          order: this.filesCount[this.newDep.type],
        }),
      });
      this.newDep = {
        type: "script",
        url: "",
      };
    },
    removeVariable(idx) {
      const clone = JSON.parse(JSON.stringify(this.globalVariables));
      clone.splice(idx, 1);
      this.$emit("update", { globalVariables: clone });
    },
    addVariable() {
      this.$emit("update", {
        globalVariables: this.globalVariables.concat({
          name: this.newVariable.name,
          value: this.newVariable.value,
        }),
      });
      this.newVariable = {
        name: "",
        value: "",
      };
    },
    updateVariableField(field, value, idx) {
      const clone = JSON.parse(JSON.stringify(this.globalVariables));
      clone[idx][field] = value
      this.$emit("update", { globalVariables: clone });
    },
    updateSiteTitle(title) {
      this.$emit("update", { siteTitle: title });
    },
    updateField(field, value) {
      console.log('field, value: ', field, value);
      this.$emit("update", { [field]: value });
    },
    updateJs(code) {
      this.updateField('globalJs', code)
    },
    updateCss(code) {
      this.updateField('globalCss', code)
    },
    async handleUploadFile(ev) {
      this.newDep.loading = true
      const res = await uploadFile(ev.target.files[0])
      console.log('res: ', res);
      this.newDep.url = `file:${res.key}`
      this.newDep.loading = false
    },
    async handleEditFile(url) {
      this.showFileEdit = true
      let type = ''
      const content = await fetch(url.replace('file:', `${api_host}/s3/`)).then(res => {
        type = res.headers.get('Content-Type')
        return res.text()
      })
      this.editFile = {
        key: url,
        type,
        content
      }
    },
    async saveEditFile() {
      this.editFileSaving = true
      console.log(this.editFile.content)
      const blob = new Blob([this.editFile.content], {type: this.editFile.type});
      const file = new File([blob], this.editFile.key, { type: this.editFile.type })
      const res = await uploadFile(file, this.editFile.key.replace('file:', ''))
      console.log(res)
      this.editFileSaving = false
      this.closeEditFile()
    },
    closeEditFile () {
      this.showFileEdit = false
      this.editFile = { key: '', content: '' }
    },
    sortFile (direction, idx) {
      if (this.orderedExtFile[idx + direction].type !== this.orderedExtFile[idx].type) {
        return
      }
      const updatedFiles = this.orderedExtFile.map((f, fIdx) => {
        return {
          ...f,
          order: fIdx === idx
            ? fIdx + direction
            : fIdx === idx + direction
              ? fIdx - direction
              : f.order
        }
      })
      this.$emit("update", {
        extFiles: updatedFiles,
      });
    }
  },
};
</script>
<style scoped>
.code-field {
  font-family: consolas;
}

.custom-file-input {
  background: #00aeef;
}

.custom-file-input:hover {
  background: #02a2dd;
}

.bullet-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #4b5563;
  display: inline-block;
}

.fa-spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.global-editor-max-height {
  max-height: calc(100vh - 486px);
}
</style>
