<template>
  <TreeNode
    :node="compTree"
    v-bind="$attrs"
  />
</template>
<script>
import TreeNode from '../ui/TreeNode.vue'

export default {
  name: 'ComponentTree',
  props: {
    components: {
      type: Array,
      default: () => ([])
    },
    currentComponent: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    TreeNode
  },
  computed: {
    compTree () {
      const appNode = this.components?.find(c => c?.name === 'app')
      return appNode ? this.childPopulate(appNode, null) : {}
    }
  },
  methods: {
    childPopulate(node, pid) {
      const children = ((node && node.deps) || []).map((depId) => {
        const depNode = this.components?.find((c) => c?._id === depId);
        return this.childPopulate(depNode, node?._id);
      });
      return {
        ...node,
        selected:
          this.currentComponent && this.currentComponent?._id === node?._id,
        children,
        pid,
      };
    },
  }
}
</script>
