<template>
  <div :style="showModal ? 'min-height: calc(100vh - 700px) !important;' : ''">
    <div class="flex justify-between items-center mt-2 mb-1 mx-1 relative">
      <!-- Remote Data Sub Tabs -->
      <ul class="list-none flex overflow-x-auto w-5/6">
        <li
          v-for="(item, index) in remoteData.data"
          :key="index"
          @click="selectItem(index)"
          class="inline-block mr-4 pr-4 border-r border-blue-100 cursor-pointer relative group flex-shrink-0 text-xs"
          :class="{
            'selected-remote-item-class font-semibold':
              selectedItemIndex === index,
            'hover:text-gray-700': selectedItemIndex != index,
          }"
        >
          {{ item.key }}
          <!-- Button to remove an item; appears on hover -->
          <button
            @click.stop="removeItem(item)"
            class="absolute bottom-1 right-1 hidden group-hover:block text-gray-500 hover:text-gray-700"
            title="Delete item"
          >
            <i class="fas fa-times fa-sm"></i>
          </button>
        </li>
      </ul>
      <div class="relative">
        <!-- Add Item Dialog -->
        <div
          v-if="showModal"
          class="modal-content absolute right-full mr-2 top-0 bg-white border border-gray-200 rounded-lg shadow-md p-3 z-10 w-64"
        >
          <!-- Input field to capture new item key -->
          <input
            v-model="newItem.key"
            type="text"
            placeholder="Enter remote data key"
            class="w-full mb-3 px-2 py-1 border rounded text-xs custom-focus"
          />
          <div class="flex justify-end space-x-2">
            <!-- Cancel button to close the modal -->
            <button
              @click="toggleModal"
              class="ml-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded text-xs self-settings-cancel-btn"
            >
              Cancel
            </button>
            <!-- Add button to emit new item to parent component -->
            <button
              @click="addNewItem(remoteData.type)"
              class="text-white font-bold py-1 px-2 rounded text-xs self-settings-add-btn"
              :class="
                !newItem.key
                  ? 'cursor-not-allowed bg-blue-300'
                  : 'bg-blue-400 hover:bg-blue-500'
              "
              :disabled="!newItem.key"
            >
              Add
            </button>
          </div>
        </div>
        <!-- Button to open modal for adding new remote data item -->
        <button
          @click="toggleModal($event)"
          class="self-settings-add-btn flex items-center text-xs"
        >
          <!-- <span class="text-lg mr-1">+</span>  -->
          <i class="fas fa-plus fa-xs mr-1"></i>
          Add Remote Data
        </button>
      </div>
    </div>

    <!-- Remote Data Sub Tab -->
    <RemoteDataSubTab
      v-if="remoteData.data.length > 0 && remoteData.data[selectedItemIndex]"
      :item="remoteData.data[selectedItemIndex]"
      @update="updateItem"
    />

    <!-- Display message if no remote data items are available -->
    <div v-else class="opacity-50 pt-2">
      No remote data items available. Click the +Add button to add one.
    </div>
  </div>
</template>

<script>
import RemoteDataSubTab from "@/components/remoteDataComponents/RemoteDataSubTab.vue";

export default {
  name: "RemoteData",
  props: ["remoteData"],
  data() {
    return {
      showModal: false,
      newItem: {
        key: "",
      },
      selectedItemIndex: 0,
    };
  },
  components: {
    RemoteDataSubTab,
  },
  methods: {
    /**
     * Toggles the visibility of the modal to add a new item.
     * If the modal is shown, resets the `newItem` key to an empty string.
     * @param {Event} event - Event object used to stop propagation when triggered by button click.
     */
    toggleModal(event) {
      if (event) event.stopPropagation(); // Prevent event from propagating to parent
      this.showModal = !this.showModal; // Toggle modal visibility
      if (this.showModal) {
        this.newItem = {
          key: "", // Reset new item key when opening modal
        };
      }
    },

    /**
     * Emits an event to add the new item to the remote data list.
     * Checks if the `newItem.key` is filled before emitting.
     * @param {string} type - The type of remote data being added.
     */
    addNewItem(type) {
      if (this.newItem.key) {
        this.$emit("add", {
          name: this.newItem.key, // Send the key value of the new item
          type, // Send the type passed into the method
        });
        this.toggleModal(); // Close the modal after item is added
        this.selectedItemIndex = this.remoteData.data.length; // Update selected index
      } else {
        alert("Please fill in all fields."); // Alert if key is empty
      }
    },

    /**
     * Closes the modal if the user clicks outside the modal content.
     * Listens for the 'click' event and checks if the click target is outside the modal.
     * @param {Event} event - The event triggered by clicking outside the modal.
     */
    closeModalOnOutsideClick(event) {
      if (this.showModal && !event.target.closest(".modal-content")) {
        this.toggleModal(); // Close the modal
      }
    },

    /**
     * Selects an item in the list to display its details in the RemoteDataSubTab component.
     * @param {number} index - The index of the selected item.
     */
    selectItem(index) {
      this.selectedItemIndex = index; // Set the selected item index
    },

    /**
     * Emits an update event to update the selected item with new data.
     * @param {Object} updatedItem - The updated item data.
     */
    updateItem(updatedItem) {
      this.$emit("update", { idx: updatedItem.idx, data: updatedItem.data }); // Emit update event
    },

    /**
     * Removes an item from the list after confirmation and updates the selected item index.
     * - Confirms the action with the user before proceeding with deletion.
     * - Emits a "remove:item" event to notify the parent component of the removal.
     * - After removing the item, adjusts `selectedItemIndex` to ensure it remains within bounds.
     *
     * @param {Object} item - The item to be removed from `remoteData.data`.
     */
    removeItem(item) {
      if (window.confirm("Are you sure?")) {
        const itemIndex = this.remoteData.data.indexOf(item);
        this.$emit("remove:item", item);

        this.$nextTick(() => {
          // Adjust selectedItemIndex only if there are still items left
          if (this.remoteData.data.length > 0) {
            if (itemIndex <= this.selectedItemIndex) {
              // If deleted item is at or before the selected index, adjust selectedItemIndex
              this.selectedItemIndex = Math.min(
                this.selectedItemIndex,
                this.remoteData.data.length - 1
              );
            }
          } else {
            // If all items are deleted, reset selectedItemIndex to a safe default (e.g., 0)
            this.selectedItemIndex = 0;
          }
        });
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.closeModalOnOutsideClick); // Add event listener for closing modal when clicking outside
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeModalOnOutsideClick); // Clean up event listener when component is destroyed
  },
};
</script>

<style scoped>
.group:hover .group-hover\:block {
  display: block;
}
.selected-remote-item-class {
  color: #00aeef !important;
}
</style>
