<template>
  <div class="px-1 pt-2" :style="showModal ? 'min-height: calc(100vh - 700px) !important;' : ''">
    <!-- Button to open modal to add a new method -->
    <div class="flex justify-end mb-1">
      <div class="relative">
        <button
          @click="toggleModal"
          class="self-settings-add-btn flex items-center text-xs"
        >
          <!-- <span class="text-lg mr-1">+</span>  -->
          <i class="fas fa-plus fa-xs mr-1"></i>
          Add Computed
        </button>

        <!-- Modal Dialog for Adding New Computed -->
        <div
          v-if="showModal"
          class="absolute right-full mr-2 top-0 bg-white border border-gray-200 rounded-lg shadow-md p-3 pb-2.5 z-10 w-64"
        >
          <!-- Computed Name Input -->
          <input
            v-model="newComputed.name"
            type="text"
            placeholder="Enter Computed name"
            class="w-full mb-3 px-2 py-1 border rounded text-xs custom-focus"
          />
          <div class="flex justify-end space-x-2">
            <!-- Cancel Button -->
            <button
              @click="toggleModal"
              class="ml-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded text-xs self-settings-cancel-btn"
            >
              Cancel
            </button>
            <!-- Add Computed Button -->
            <button
              @click="addComputed"
              class="text-white font-bold py-1 px-2 rounded text-xs self-settings-add-btn"
              :class="
                !newComputed.name
                  ? 'cursor-not-allowed bg-blue-300'
                  : 'bg-blue-400 hover:bg-blue-500'
              "
              :disabled="!newComputed.name"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Displaying added computed -->
    <div v-for="(computed, index) in localComputed" :key="index">
      <div class="rounded-lg py-2 flex flex-col gap-2">
        <!-- Computed Name Input Field -->
        <div class="flex items-center gap-3">
          <label class="text-gray-700 font-medium w-16 text-xs">Name:</label>
          <input
            v-model="computed.name"
            type="text"
            class="flex-grow px-3 py-1 border border-gray-300 custom-focus rounded-md"
            placeholder="Enter computed name"
            @input="updateComputedData(index, 'name', $event.target.value)"
          />
        </div>
        <!-- Computed Body Input Field (Template Editor) -->
        <div class="flex items-center gap-4">
          <label class="text-gray-700 font-medium text-left ml-2.5 text-xs">Body:</label>
          <div class="zoom-view-fixed w-full border rounded-md p-2 ml-3">
            <!-- Template Editor for Computed Body -->
            <ZoomView>
              <TemplateEditor
                :template="computed.body"
                @set="(code) => updateComputedBody(index, code)"
              />
            </ZoomView>
          </div>
        </div>
        <!-- Remove Computed Button -->
        <button
          @click="removeComputed(index)"
          class="text-gray-500 hover:text-red-500 self-end"
          aria-label="Remove Computed"
        >
          <i class="fas fa-trash fa-sm"></i>
        </button>
      </div>
    </div>

    <!-- Message when no Computed are added -->
    <div v-if="localComputed.length === 0" class="opacity-50 pt-2">
      No Computed added yet. Click the +Add button to add a new Computed.
    </div>
  </div>
</template>

<script>
import TemplateEditor from "./TemplateEditor.vue";
import ZoomView from "../ui/ZoomView.vue";

export default {
  name: "ComputedComponent",
  props: {
    // The 'computed' prop receives an array of computed data
    computed: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 'localComputed' stores a local copy of computed for easy editing
      localComputed: [],
      // 'showModal' controls the visibility of the modal
      showModal: false,
      // 'newComputed' holds the data for a new Computed being added
      newComputed: { name: "", body: "" },
    };
  },
  components: {
    TemplateEditor,
    ZoomView,
  },
  methods: {
    /**
     * Toggles the visibility of the modal.
     * Resets the 'newComputed' object if the modal is closed.
     */
    toggleModal() {
      this.showModal = !this.showModal;
      if (!this.showModal) {
        this.newComputed = { name: "", body: "" };
      }
    },

    /**
     * Adds a new computed to the list by emitting the 'add' event.
     * If the computed name is not empty, it sends the new computed name.
     */
    addComputed() {
      if (this.newComputed.name.trim()) {
        let name = this.newComputed.name.trim();
        this.$emit("add", { name });
        this.toggleModal();
      }
    },

    /**
     * Removes a computed at a given index by emitting the 'remove' event.
     * A confirmation prompt is displayed before removing the computed.
     *
     * @param {number} index - Index of the computed to remove
     */
    removeComputed(index) {
      if (window.confirm("Are you sure you want to remove this Computed?")) {
        this.$emit("remove", { index });
      }
    },

    /**
     * Updates a specific field of a computed based on the field name and value.
     * This computed is triggered when the user edits computed properties.
     *
     * @param {number} index - Index of the computed being edited
     * @param {string} field - The name of the field being updated (e.g., 'name')
     * @param {any} value - The new value for the field
     */
    updateComputedData(index, field, value) {
      this.$emit("update", { key: field, index, value: value });
    },

    /**
     * Updates the body of a computed when edited using the TemplateEditor component.
     *
     * @param {number} index - Index of the computed being edited
     * @param {string} body - The new body content for the computed
     */
    updateComputedBody(index, body) {
      this.$emit("update", { key: "body", index, value: body });
    },
  },
  watch: {
    /**
     * Watches for changes in the 'computed' prop and updates the 'localComputed' array.
     *
     * @param {Array} newComputed - The new array of computed passed from the parent
     */
    computed: {
      handler(newComputed) {
        this.localComputed = [...newComputed];
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    /**
     * Initializes the 'localComputed' array with the computed prop.
     */
    this.localComputed = [...this.computed];
  },
};
</script>

<style scoped>
.self-settings-add-btn {
  font-weight: bold;
  transition: background-color 0.2s;
}

.zoom-view-fixed {
  height: 80px;
  overflow-y: auto;
}
</style>
